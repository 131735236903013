<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1>Анкета &laquo;{{ config.anket.title }}&raquo;</h1>


          <div class="anketInfo">

            <div class="anketInfo-top">
              <div class="anketInfo-top-left">
                <table>
                  <tbody>
                  <tr>
                    <td>Субъект РФ</td>
                    <td>{{ getAnketRegionName() || '-' }}</td>
                  </tr>
                  <tr>
                    <td>ЦЗН</td>
                    <td>{{ getAnketCznName() || '-' }}</td>
                  </tr>
                  <tr>
                    <td>Дата анкетирования</td>
                    <td>{{ anketFormData.localDateTime | dateTimeFormat }}</td>
                  </tr>
                  <tr>
                    <td>Электронная почта</td>
                    <td>{{ anketFormData.email }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="anketInfo-top-right">
              </div>
            </div>

            <v-tabs>
              <template  v-for="(step, stepIndex) in this.config.anket.steps">
              <v-tab :href="'#anket'+stepIndex"  :key="'asfrh'+stepIndex">
                анкета {{stepIndex+1}}
              </v-tab>
              <v-tab-item :value="'anket'+stepIndex" :key="'afassfrh'+stepIndex">
                <h2>{{step.name}}</h2>
                <div class="badges">
                </div>

                <table class="anket-table">
                  <tbody>
                  <template v-for="(q, qIndex) in step.questions">
                    <tr :key="'utjfmdf'+stepIndex+'.'+qIndex">
                      <td>{{qIndex+1}}.</td>
                      <td>{{q.text}}</td>
                      <!--                    <td>{{ (t=q.options[q.answer]) ? t.text : ''}}</td>-->
                      <td>{{ q.typeText ? q.answer : (q.options[q.answer] ? q.options[q.answer].text : '') }}</td>
                    </tr>
                    <template v-if="q.subQuestions?.length > 0">
                      <tr v-for="(sq, sqIndex) in q.subQuestions" :key="'utjfmdf'+stepIndex+'.'+sqIndex">
                        <td>{{qIndex + 1}}.{{sqIndex + 1}}.</td>
                        <td>{{sq.text}}</td>
                        <td>{{ sq.typeText ? sq.answer : (sq.options[sq.answer] ? sq.options[sq.answer].text : '') }}</td>
                      </tr>
                    </template>
                  </template>


<!--                  <tr v-for="(q, qIndex) in step.questions" :key="'utjfmdf'+stepIndex+'.'+qIndex">-->
<!--                    <td>{{qIndex+1}}.</td>-->
<!--                    <td>{{q.text}}</td>-->
<!--&lt;!&ndash;                    <td>{{ (t=q.options[q.answer]) ? t.text : ''}}</td>&ndash;&gt;-->
<!--                    <td>{{ q.typeText ? q.answer : (q.options[q.answer] ? q.options[q.answer].text : '') }}</td>-->
<!--                  </tr>-->
                  </tbody>
                </table>
              </v-tab-item>
              </template>
            </v-tabs>

          </div>


        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main";
</style>

<style lang="scss" scoped>
</style>

<script>

import {restoreAnswers} from "../../modules/questions";

export default {
  name: "AnketInfo",
  props: {},
  components: {},
  data() {
    return {
      apiLoaded: false,
      errorText: null
    };
  },
  methods: {
    async loadAnketFormData() {
      let req = await this.$getApi("/ankets/getAnketFormData", {id: this.anketId})
      if (req.ok) {
        this.anketFormData = req.payload
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },
    async loadAnketConfig(configId) {
      let req = await this.$getApi("/ankets/getConfig", {id: configId})
      if (req.ok) {
        this.config = req.payload
        this.config.anket = JSON.parse(this.config.anketJson)
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    getDepartment(){
      const departmentId = this.anketFormData.cznName // да, на самом деле в этом поле хранится cznId
      return this.$cznDepartmentsList.find(dep => dep.id?.toString() === departmentId)
    },

    getAnketRegionName(){
      return this.getDepartment()?.region.name
    },

    getAnketCznName(){
      return this.getDepartment()?.name
    }

  },
  filters: {
    dateTimeFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {
        timeZone: 'Europe/Moscow',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  async beforeMount() {
    this.anketId = this.$route.params.id
    await this.loadAnketFormData(this.anketId)
    await this.loadAnketConfig(this.anketFormData.configId)
    restoreAnswers(this.config.anket, this.anketFormData.answers)
    this.apiLoaded = true

  }
};
</script>
